<template>
    <div>

        <div align="center">
            <h4>Problemi segnalati sulla quotazione</h4>
            <hr>
        </div>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">

            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div class="row justify-center">

            <div class="col-12 col-md-5 q-px-md">
                <h5 class="q-mt-sm">Dati della quotazione</h5>
                <q-list  separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Area:</q-item-label>
                            <q-item-label caption>{{quotazione.area}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Prodotto:</q-item-label>
                            <q-item-label caption>{{quotazione.id_prodotto}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Numero preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.guid_preventivo}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Descrizione preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.nominativo}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Stato della quotazione:</q-item-label>
                            <q-item-label caption>{{getStato}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Unit&agrave; Operativa:</q-item-label>
                            <q-item-label caption>{{quotazione.unita_operativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Operatore:</q-item-label>
                            <q-item-label caption>{{quotazione.operatore}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>


            </div>

            <div class="col-12 col-md-7 q-px-md">
                <h5 class="q-mt-sm">Elenco dei problemi</h5>

                <div v-show="!isProblemiPresenti">
                    <h5>Nessun problema &egrave; stato identificato per questa quotazione.</h5>
                </div>

                <q-list separator bordered>
                    <q-item
                        v-for="(problema,index) in elenco_problemi" v-bind:key="index"
                        clickable v-ripple
                         @click.native="onSelezionaProblema(problema)">
                        <q-item-section>
                            <q-item-label>Descrizione problema:</q-item-label>
                            <q-item-label caption>{{problema.note}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Segnalato da:</q-item-label>
                            <q-item-label caption>{{problema.persona_operativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Data segnalazione:</q-item-label>
                            <q-item-label caption>{{getDataProblema(problema.segnalato_at)}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Problema risolto:</q-item-label>
                            <q-item-label caption>{{isProblemaRisolto(problema)}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Data risoluzione problema:</q-item-label>
                            <q-item-label caption>{{getDataProblema(problema.risolto_at)}}</q-item-label>
                        </q-item-section>
                        <q-item-section avatar>
                            <q-avatar v-show="!problema.is_risolto" color="orange-3" text-color="black" icon="mdi-download" />
                            <q-avatar v-show="problema.is_risolto"  color="orange-3" text-color="black" icon="mdi-check-outline"  size="36px" />
                        </q-item-section>

                    </q-item>
                </q-list>

            </div>

            <div class="col-12 col-md-12">
                <br><hr>
            </div>

            <div class="col-12 col-md-12" align="center">

                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    :disable="!is_loading"
                    @click.native="onClickIndietro()"
                />

            </div>

        </div>

        <br><br><br><br><br>

    </div>
</template>

<script>
    import { mapFields} from 'vuex-map-fields';
    import { mapState,mapActions } from 'vuex';
    import QQButton from "@/components/QQButton.vue";
    import commonLib from "@/libs/commonLib";
    //import api from "@/libs/api";

    export default {
        name: "ProblemiSegnalatiQuotazione",
        components: {
            QQButton
        },
        data() {
            return {
                elenco_problemi: [],
                annotazione: "",
                quotazione: {},
                is_loading: true
            }
        },
         computed: {
            ...mapFields('gestioneQuotazioni', [
                "idquotazione"
            ]),
            ...mapState({
                formPreventivo: state => state.formPreventivo.formPreventivo
            }),
            getStato() {
                if (commonLib.isEmpty(this.quotazione)) return "";

                return this.quotazione.stato.replaceAll("_"," ");
            },
            isProblemiPresenti() {
                if (commonLib.isEmpty(this.elenco_problemi)) return false;
                return (this.elenco_problemi.length > 0);
            }
         },
        methods: {
            ...mapActions({
                fetchDatiQuotazione: "gestioneQuotazioni/fetchDatiQuotazione",
                fetchElencoProblemiSuQuotazione: "gestioneQuotazioni/fetchElencoProblemiSuQuotazione",
                RisolviProblema: "gestioneQuotazioni/RisolviProblema"
            }),
            isProblemaRisolto(problema) {
                if (problema.is_risolto) return "Si";

                return "No";
            },
            getDataProblema(data) {
                if (commonLib.isEmpty(data)) return "";

                let MiaData = (data+"").substring(0,10);
                let element = MiaData.split("-");
                return element[2]+"/"+element[1]+"/"+element[0];
            },
            async onSelezionaProblema(problema) {
                // Se sta lavorando esce senza fare altro
                if (!this.is_loading) return;

                let problema_risolto = problema.is_risolto;

                if (!problema_risolto) {
                    this.$q.dialog({
                        cancel: {
                            focus: true,
                            flat: false,
                            label: "No, neanche un po`",
                            color: "primary"
                        },
                        ok: {
                            color: "secondary",
                            flat: false,
                            label: "Si, lo sono"
                        },
                        title: 'Attenzione',
                        message: 'Sei sicuro di aver risolto questo problema?',
                    }).onOk(async () => {
                       // let IDQuotazione = this.quotazione.idquotazione;
                       this.is_loading = false;
                        let IDProblema = problema.idproblema;

                        await this.RisolviProblema(IDProblema);

                        this.elenco_problemi = await this.fetchElencoProblemiSuQuotazione(this.quotazione.idquotazione);
                        this.is_loading = true;
                    });

                } else {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Il problema selezionato risulta gia risolto in data '+this.getDataProblema(problema.risolto_at),
                    });
                }
            },
            onClickIndietro() {
                this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
            }
        },
        async mounted() {
            this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
            this.elenco_problemi = await this.fetchElencoProblemiSuQuotazione(this.quotazione.idquotazione);
        }
    }

</script>
